import React, { useState, useRef, useEffect } from 'react';
import { createRoot } from 'react-dom/client';
import {
    Alert,
    Avatar,
    Box,
    Button,
    Divider,
    Grid,
    Paper,
    Snackbar,
    Stack,
    TextField,
    Typography
} from "@mui/material";

import csrf  from '../modules/csrf';

const Login = (props: any, deprecatedLegacyContext?: any) => {

    const email = useRef<HTMLInputElement>(null);
    const password = useRef<HTMLInputElement>(null);

    const [errorMessage, setErrorMessage] = useState<string>('');
    const [openErrorMessage, setErrorMessageOpen] = useState(false);

    const handleErrorMessage = (message: string) => {
        setErrorMessage(message);
        setErrorMessageOpen(true);
    }
    const handleErrorMessageClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setErrorMessageOpen(false);
    };

    const handleLoginClick = async () => {
        const submitParam: any = {
            'email'   : email.current!.value,
            'password': password.current!.value
        }

        if (Object.entries(submitParam).map(([_, value]) => value).some((value) => !Boolean(value))) {
            handleErrorMessage('未入力の項目があります');
            return;
        }

        try {
            //fetch前のawaitは外さないこと
            await fetch('/ajax/login',
                {
                    method: 'POST',
                    headers: { 'X-CSRFToken': csrf, 'Content-Type': 'application/json' },
                    body: JSON.stringify(submitParam)
                })
                .then((response) => {
                    if (!response.ok) {
                        return Promise.reject(response.json());
                    } else {
                        location.href = '/menu';
                    }
                })
        } catch (error: any) {
            let errorMessage;
            if (error instanceof Error) {
                errorMessage = error;
            } else {
                errorMessage = await error.then((value: any) => {
                    return Promise.resolve(value?.message ?? error);
                });
            }
            handleErrorMessage(errorMessage);
        }
    }

    const handleUserSelfEntryClick = async () => {
        const url = `http://${location.host}/user/self/entry?csrf_token=${csrf}`;
        location.href = url;
    }

    useEffect(()=>{

        //@ts-ignore
        // input_client_school_id.current.value= "2";
        //@ts-ignore
        // email.current.value= "tamezane@ksjg.com";
        //@ts-ignore
        // password.current.value= "hats988";

    },[]);

    return (
        <>
        <Grid>
            <Snackbar
                open={openErrorMessage}
                autoHideDuration={3000}
                onClose={handleErrorMessageClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <Alert onClose={handleErrorMessageClose} severity="error" sx={{ width: '100%' }}>
                    {errorMessage}
                </Alert>
            </Snackbar>
            <Paper
                elevation={3}
                sx={{
                    p: 3,
                    height: "75vh",
                    width: "230px",
                    m: "30px auto"
                }}
            >
                <Stack
                    direction="column"
                    justifyContent="flex-start" //多分、デフォルトflex-startなので省略できる。
                    alignItems="center"
                    // spacing={10}
                >
                    <Avatar alt="BIEQS" sx={{ width: 200, height: 200 }} src="../static/logo/logo_transparent.png" />
                    <TextField
                        label="登録Eメール"
                        variant="standard"
                        fullWidth
                        required
                        inputRef={email}
                    />
                    <TextField
                        type="password"
                        label="パスワード"
                        variant="standard"
                        fullWidth
                        required
                        inputRef={password}
                        sx={{mt:2}}
                    />
                    <Box mt={3}>
                        <Button color="primary" variant="contained" fullWidth onClick={handleLoginClick}>
                            サインイン
                        </Button>
                    </Box>
                    <Divider sx={{ bgcolor: "green" }}/>
                    <Box mt={3}>
                        <Button color="inherit" variant="contained" fullWidth onClick={handleUserSelfEntryClick}>
                            初めての方
                        </Button>
                    </Box>
                    <Box mt={5}>
                        <Grid
                            container
                            direction="column"
                            justifyContent="flex-start" //多分、デフォルトflex-startなので省略できる。
                            alignItems="center"
                        >
                            <Typography variant='caption' align="center" >
                                ご利用可能時間：6:00～22:00
                            </Typography>
                            {/* <Typography variant='caption' align="center" >
                                （学）静岡自動車学園
                            </Typography> */}
                        </Grid>
                    </Box>

                </Stack>
            </Paper>
        </Grid>
        </>
    );
};

const appElement = document.getElementById('app');
if (!appElement) throw new Error('Failed to find the root element');
const app = createRoot(appElement);
//@ts-ignore
app.render(<Login/>);
